<template>
	<div class="scenery">
		<div class="scenery_title">
			<p class="scenery_title_cn">园区风采</p>
			<p class="scenery_title_en">PARK STYLE</p>
		</div>

		<div class="scenery_list">
			<div v-for="(img, index) in list" :key="index" class="scenery_item" @click="itemClick(index)">
				<van-image class="scenery_item_img" fit="cover" lazy-load :src="img" />
			</div>
		</div>

		<van-image-preview v-model="show" closeable :start-position="imgIndex" :images="list" @change="onChange">
			<template v-slot:index>{{ imgIndex + 1 }} / {{ list.length }}</template>
		</van-image-preview>

		<div class="pre_img_icon_box" v-if="show">
			<van-icon class="pre_img_icon pre_img_prev" name="arrow-left" @click="prev" />
			<van-icon class="pre_img_icon pre_img_next" name="arrow" @click="next" />
		</div>
	</div>
</template>

<script>
	export default {
		name: "index",
		data() {
			return {
				show: false,
				imgIndex: 0,
				list: []
			}
		},
		created() {
			this.init();
		},
		methods: {
			async init() {
				const res = await this.$api.imgList();
				let list = res.data;
				list = list.map(item => item.img_url);
				this.list = list;
			},

			itemClick(index) {
				this.imgIndex = index;
				this.show = true;
			},

			onChange(index) {
				this.imgIndex = index;
			},

			prev() {
				if( this.imgIndex > 0 ) {
					this.imgIndex--;
				}
			},

			next() {
				if( this.imgIndex < this.list.length-1 ) {
					this.imgIndex++;
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	@import '~@/assets/fonts/fonts.css';

	.scenery {
		width: 100%;
		max-width: 100rem;
		margin: 0 auto;
		padding: 10rem 1rem;
	}

	.scenery_title {
		color: @greenColor;
		padding-bottom: 2.5rem;
		font-family: 'shangshushui';
	}

	.scenery_title_cn {
		font-size: 5.4rem;
	}

	.scenery_title_en {
		font-size: 1.5rem;
		margin-top: 0.5rem;
	}

	.scenery_list {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		justify-content: space-between;
		margin-top: 3.2rem;
		padding: 0 3rem;
	}

	.scenery_item {
		flex-shrink: 0;
		width: 49%;
		height: 0;
		padding-top: calc(49% / 1.4);
		position: relative;
		margin-top: 1.5rem;
	}

	.scenery_item_img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.pre_img_icon {
		color: #ffffff;
		text-shadow: 0 0 5px #000;
		font-size: 8rem;
		position: fixed;
		top: 50%;
		z-index: 9999;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.pre_img_prev {
		left: 2rem;
	}

	.pre_img_next {
		right: 2rem;
	}





	@media screen and (min-width: 800px) {
		.scenery_item:nth-of-type(1),
		.scenery_item:nth-of-type(2) {
			margin-bottom: 0;
		}
	}




	@media screen and (max-width: 800px) {
		.scenery {
			width: 95%;
			padding: 3.1rem 0;
		}

		.scenery_title {
			padding-bottom: 0.8rem;
		}

		.scenery_title_cn {
			font-size: 1.9rem;
		}

		.scenery_title_en {
			font-size: 0.6rem;
			margin-top: 0.2rem;
		}

		.scenery_list {
			margin-top: 2.2rem;
			padding: 0 1rem;
		}

		.scenery_item {
			flex-shrink: 0;
			width: 100%;
			height: 0;
			padding-top: calc(100% / 1.8);
			margin-top: 1rem;
		}

		.scenery_item:nth-of-type(1) {
			margin-bottom: 0;
		}

		.pre_img_icon_box {
			display: none;
		}
	}
</style>